import * as yup from 'yup';

export const eventTypesRemindersSchema = yup
	.object({
		createOptions: yup
			.object({
				options: yup
					.object({
						sms: yup.boolean().default(false),
						webhook: yup.boolean().default(false),
						whatsapp: yup.boolean().default(false),
					})
					.default({}),
			})
			.default({}),
	})
	.default({});
