import useAuth from '@/hooks/useAuth';
import api_client from '@/repositories';
import { IUpdateArgs } from '@/repositories/user.api';
import ILoginResponse from '@/types/LoginResponse';
import { ICustomQueryArgsRepository } from '@/types/utils/ICustomQueryArgs';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';

export const QK_LIST_USERS = 'list-users';
export const QK_USERS = 'users';

export function useListUsers() {
	return useQuery({
		queryFn: api_client.user.list,
		queryKey: [QK_LIST_USERS],
	});
}

export function useGetUser() {
	const auth = useAuth();
	const userId = auth.getCurrentUser().userId;

	return useQuery({
		queryFn: () => api_client.user.get({ id: userId as string }),
		queryKey: [QK_USERS, userId],
	});
}

/**
 * Allows to change the given fields on the user.
 * The old fields are preserved. Once the mutation is completed the auth is updated with the new data.
 */
export function useUpdateUserMutation() {
	const queryClient = useQueryClient();
	const auth = useAuth();

	return useMutation({
		mutationFn: (updatedValues: Partial<IUpdateArgs>) => {
			// Since this performs a patch we need to preserve the old fields
			return api_client.user.update({
				clientState: auth.getCurrentUser().clientState,
				id: auth.getCurrentUser().userId,
				locale: auth.getCurrentUser().locale,
				slug: auth.getCurrentUser().slug,
				timeZone: auth.getCurrentUser().timeZone,
				...updatedValues,
			});
		},
		/**
		 * Por una condición de carrera en el login tenemos que hacer una actualización optimista antes de que el servidor conteste.
		 *
		 */
		onMutate: async (newUserData) => {
			// Cancel any outgoing refetches sso they don't overwrite our optimistic update.
			await queryClient.cancelQueries({ queryKey: [QK_USERS, 'login'] });

			// Snapshot the previous value
			const previousValue = queryClient.getQueryData([QK_USERS, 'login']) as ILoginResponse;

			// Optimistically update to the new value
			queryClient.setQueryData([QK_USERS, 'login'], { ...previousValue, ...newUserData });

			// Return a context object with the snap-shotted value we can use on error to rollback
			return { previousValue } as { previousValue: ILoginResponse };
		},
		// If the mutation fails,
		// use the context returned from onMutate to roll back
		onError: (x, y, context) => {
			queryClient.setQueryData([QK_USERS, 'login'], context?.previousValue);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries();
		},
	});
}

type IUseLoginQueryArgs = ICustomQueryArgsRepository<typeof api_client.auth.login | null> & {
	token: string;
};

export function useLoginQuery(args: IUseLoginQueryArgs): UseQueryResult<ILoginResponse | null, Error> {
	return useQuery({
		...args,
		queryKey: [QK_USERS, 'login', String(args.token)],
		queryFn() {
			if (!args.token) {
				return null;
			}
			return api_client.auth.login();
		},
	});
}
