import IApplicationConfig from '@/types/IApplicationConfig';

export const BASE_APP_CONFIG: IApplicationConfig = {
	name: 'TimeTime.in',
	hasEmailAndPasswordLogin: true,
	hasGoogleLogin: true,
	hasMicrosoftLogin: true,
	hasGoogleCalendar: true,
	hasMicrosoftCalendar: true,
	hideSidebar: false,
	hideTopBar: false,
	BookingConfirmationPage: {
		hideBranding: false,
	},
	BookingFormPage: {
		hideBranding: false,
	},
	LoginPage: {
		imageUrl: '',
	},
	theme: {
		ttColor: 'primary',
	},
	eventTypes: {
		bookingMode: {
			defaultValue: {
				type: 'InstantBookingMode',
			},
			mode: 'W',
		},
		redirectAfterBooking: {
			defaultValue: '',
			mode: 'W',
		},
		reminders: {
			createOptions: {
				options: {
					sms: false,
					webhook: false,
					whatsapp: false,
				},
			},
		},
	},
};
