import { setToken } from '@/api_client/client';
import { useSearchParams } from '@/hooks/useSearchParams';
import googleRepository from '@/repositories/google.repository';
import { sendMessageToParentWindow } from '@/services/google-oauth.service';

export function OauthRedirectPage() {
	const search = useSearchParams();

	const authCode = search.get('code');
	const stateBase64 = search.get('state');

	if (!authCode || !stateBase64) {
		throwError('Invalid URL');
	}

	const decodedState = atob(stateBase64!);
	const state = JSON.parse(decodedState);

	if (!state.authToken) {
		throwError('Missing token in state');
	}

	if (!state.provider) {
		throwError('Missing provider in state');
	}

	if (!state.redirectUri) {
		throwError('Missing redirectUri in state');
	}

	setToken(state.authToken);

	googleRepository
		.consent(authCode!, state.redirectUri)
		.then((res) => res.data)
		.then(() => {
			sendMessageToParentWindow({ status: 'SUCCESS' });
		})
		.catch(() => {
			sendMessageToParentWindow({ status: 'FAILED', message: 'SERVER_ERROR' });
		})
		.finally(() => {
			setTimeout(() => {
				window.close();
			}, 1000);
		});

	return <div> ... </div>;
}

function throwError(message: string) {
	sendMessageToParentWindow({ message, status: 'FAILED' });
	setTimeout(() => {
		window.close();
	}, 200);
}
