import AuthContext from '@/context/Auth.context';
import useFirebase from '@/hooks/useFirebase';
import dummy_user_get_response from '@/mocks/dummys/server_responses/dummy_user_get_response';
import { useLoginQuery } from '@/queries/user.queries';
import firebaseService from '@/services/firebase.service';
import helpService from '@/services/help.service';
import ISessionContextState from '@/types/ISessionContextState';
import IUser from '@/types/IUser';
import i18next from 'i18next';
import React, { useEffect } from 'react';

interface AuthProviderProps {
	initialState?: ISessionContextState;
}

export default function AuthProvider(props: React.PropsWithChildren<AuthProviderProps>) {
	const { token } = useFirebase();

	const loginQuery = useLoginQuery({
		token,
		initialData: null,
		refetchOnWindowFocus: false,
		enabled: !!token,
	});

	useEffect(() => {
		if (loginQuery.data?.locale) {
			i18next.changeLanguage(loginQuery.data.locale);
		}
	}, [loginQuery.data]);

	const firebaseUser = firebaseService.auth.currentUser;

	const user = loginQuery.data
		? { ...loginQuery.data, imageSrc: firebaseUser?.photoURL ?? '', username: firebaseUser?.displayName ?? '' }
		: null;

	_startUserTracking(user);

	return <AuthContext.Provider value={{ status: loginQuery.status, user }}>{props.children}</AuthContext.Provider>;
}

export function AuthProviderTest(props: React.PropsWithChildren<AuthProviderProps>) {
	return (
		<AuthContext.Provider
			value={{
				status: 'success',
				user: { ...dummy_user_get_response, imageSrc: 'dummy_image_src', username: 'dummy_username' },
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
}

function _startUserTracking(user: IUser | null) {
	if (user) {
		helpService.identify({ email: user.email });
		if (window.clarity) {
			window.clarity('identify', user.email);
		}
	}
}
