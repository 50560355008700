import * as yup from 'yup';

export const themeSchema = yup
	.object()
	.shape({
		ttColor: yup.string().required().default('primary'),
	})
	.default({
		ttColor: 'primary',
	});
