import { EP_CONSENT } from '@/constants/endpoints';
import client from '../api_client/client';

async function consent(authCode: string, redirectUri?: string) {
	if (!redirectUri) {
		return client.post(EP_CONSENT, { authCode });
	}
	return client.post(EP_CONSENT, { authCode, redirectUri });
}

export default {
	consent,
};
