import { IEventTypesConfig } from '@/types/IApplicationConfig';
import * as yup from 'yup';
import { BASE_APP_CONFIG } from '../base_app_config';
import { bookingModeSchema } from './booking-mode.schema';
import { eventTypesRemindersSchema } from './event-types-reminders.schema';
import { featureModeSchema } from './feature-mode.schema';

export const eventTypesSchema = yup
	.object({
		redirectAfterBooking: yup
			.object({
				mode: featureModeSchema,
				defaultValue: yup.string().default(''),
			})
			.default(BASE_APP_CONFIG.eventTypes.redirectAfterBooking),
		bookingMode: yup
			.object({
				mode: featureModeSchema,
				defaultValue: bookingModeSchema,
			})
			.default(BASE_APP_CONFIG.eventTypes.bookingMode),
		reminders: eventTypesRemindersSchema,
	})
	.default<IEventTypesConfig>(BASE_APP_CONFIG.eventTypes);
