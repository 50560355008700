import { ROUTE_OAUTH_REDIRECT } from '@/constants/routes';
import firebaseService from './firebase.service';

const CLIENT_ID = '338732364255-v37bkjjspf56inbcv7got9ekl6r3jnui.apps.googleusercontent.com';

const SCOPES = [
	'https://www.googleapis.com/auth/userinfo.email',
	'https://www.googleapis.com/auth/calendar.freebusy',
	'https://www.googleapis.com/auth/calendar.events.owned',
	'https://www.googleapis.com/auth/calendar.calendarlist.readonly',
];

/**
 * Manual implementation for Google OAuth
 */
async function syncCalendars() {
	const authToken = await firebaseService.getIdToken();
	const redirectUri = _gerRedirectUri();
	const finalRedirectUri = `${window.location.origin}${ROUTE_OAUTH_REDIRECT}`;
	const googleOauthURL = new URL('https://accounts.google.com/o/oauth2/v2/auth');

	googleOauthURL.searchParams.set('client_id', CLIENT_ID);
	googleOauthURL.searchParams.set('scope', SCOPES.join(' '));
	googleOauthURL.searchParams.set('redirect_uri', redirectUri);
	googleOauthURL.searchParams.set('response_type', 'code');
	googleOauthURL.searchParams.set('access_type', 'offline');
	googleOauthURL.searchParams.set('prompt', 'consent');
	googleOauthURL.searchParams.set('include_granted_scopes', 'true');
	googleOauthURL.searchParams.set('enable_granular_consent', 'true');

	googleOauthURL.searchParams.set(
		'state',
		generateBase64State({ authToken, provider: 'GOOGLE', redirectUri, finalRedirectUri })
	);

	const popup = window.open(googleOauthURL.toString(), '_blank', 'width=400,height=600');

	return new Promise<ISyncCalendarsSuccessType>((resolve, reject: (reason: ISyncCalendarsErrorType) => void) => {
		if (!popup) {
			reject({ message: 'CANNOT_OPEN_POPUP' });
			return;
		}

		const messageListener = (event: MessageEvent<IOauthPopupMessageData>) => {
			if (event.origin !== window.location.origin) {
				return;
			}

			switch (event.data?.status) {
				case 'FAILED':
					window.removeEventListener('message', messageListener);
					reject({ message: event.data.message });
					break;
				case 'SUCCESS':
					window.removeEventListener('message', messageListener);
					resolve({ message: 'OAUTH_SUCCESS' });
					break;
			}
		};

		window.addEventListener('message', messageListener);

		const checkPopupClosed = setInterval(() => {
			if (!popup) {
				clearInterval(checkPopupClosed);
				window.removeEventListener('message', messageListener);
				return reject({ message: 'POPUP_NOT_OPENED' });
			}

			if (popup.closed) {
				clearInterval(checkPopupClosed);
				window.removeEventListener('message', messageListener);
				return reject({ message: 'POPUP_CLOSED' });
			}
		}, 500);
	});
}

export default {
	syncCalendars,
};

function generateBase64State(args: {
	authToken: string;
	provider: 'GOOGLE' | 'MICROSOFT';
	redirectUri: string;
	finalRedirectUri: string;
}): string {
	const state = JSON.stringify(args);
	return btoa(state);
}

/**
 * Allows pages opened in a popup redirected by OAuth to communicate with the parent window
 */
export function sendMessageToParentWindow(data: IOauthPopupMessageData) {
	if (window.opener) {
		window.opener.postMessage(data, window.location.origin);
	}
}

export type IOauthPopupMessageData = IOauthDataSuccess | IOauthDataError;

interface IOauthDataSuccess {
	status: 'SUCCESS';
}

interface IOauthDataError {
	status: 'FAILED';
	message: string;
}

export interface ISyncCalendarsErrorType {
	message: string;
}

interface ISyncCalendarsSuccessType {
	message: string;
}

/**
 * Generates the redirect URI for the OAuth consent page
 * If the origin is not localhost or app.timetime.in use app.timetime.in as the origin
 */
function _gerRedirectUri(): string {
	if (window.location.origin.includes('localhost')) {
		return `http://localhost:3000${ROUTE_OAUTH_REDIRECT}`;
	}
	return `https://app.timetime.in${ROUTE_OAUTH_REDIRECT}`;
}
