import * as yup from 'yup';
import { BASE_APP_CONFIG } from '../base_app_config';
import { bookingConfirmationPageSchema } from './booking-confirmation-page.schema';
import { bookingFormPageSchema } from './booking-form-page.schema';
import { eventTypesSchema } from './event-types.schema';
import { loginPageSchema } from './login-page.schema';
import { themeSchema } from './theme.schema';

export const applicationConfigSchema = yup
	.object({
		name: yup.string().required().default(BASE_APP_CONFIG.name),
		hasEmailAndPasswordLogin: yup.boolean().required().default(BASE_APP_CONFIG.hasEmailAndPasswordLogin),
		hasGoogleLogin: yup.boolean().required().default(BASE_APP_CONFIG.hasGoogleLogin),
		hasMicrosoftLogin: yup.boolean().required().default(BASE_APP_CONFIG.hasMicrosoftLogin),
		hasGoogleCalendar: yup.boolean().required().default(BASE_APP_CONFIG.hasGoogleCalendar),
		hasMicrosoftCalendar: yup.boolean().required().default(BASE_APP_CONFIG.hasMicrosoftCalendar),

		LoginPage: loginPageSchema,
		BookingFormPage: bookingFormPageSchema,
		BookingConfirmationPage: bookingConfirmationPageSchema,
		theme: themeSchema,
		hideSidebar: yup.boolean().optional().default(BASE_APP_CONFIG.hideSidebar),
		hideTopBar: yup.boolean().optional().default(BASE_APP_CONFIG.hideTopBar),
		eventTypes: eventTypesSchema,
	})
	.required();
