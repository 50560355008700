import { IBookingMode, IHoldBookingMode, IInstantBookingMode } from '@/types/IEventType';
import * as yup from 'yup';

export const bookingModeSchema = yup.lazy((value): yup.Schema<IBookingMode> => {
	if (typeof value !== 'object' || value === null) {
		return instantBookingModeSchema;
	}

	switch (value.type) {
		case 'InstantBookingMode':
			return instantBookingModeSchema;
		case 'HoldBookingMode':
			return holdBookingModeSchema;
		default:
			return instantBookingModeSchema;
	}
});

const holdBookingModeSchema = yup.object({
	type: yup.string().oneOf(['HoldBookingMode']).required().default('HoldBookingMode'),
	timeout: yup.string().required().default('PT1H'),
}) as yup.Schema<IHoldBookingMode>;

const instantBookingModeSchema = yup.object({
	type: yup.string().oneOf(['InstantBookingMode']).required().default('InstantBookingMode'),
}) as yup.Schema<IInstantBookingMode>;
