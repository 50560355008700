import IApplicationConfig from '@/types/IApplicationConfig';
import { BASE_APP_CONFIG } from './base_app_config';
import { applicationConfigSchema } from './schemas/app-config.schema';

export function parseConfigurationFromQueryParams(
	baseConfig: IApplicationConfig,
	params: URLSearchParams
): IApplicationConfig {
	const config = structuredClone(baseConfig);

	const ttHideToolbar = params.get('tt_hide_sidebar');

	if (ttHideToolbar === 'true') {
		config.hideSidebar = true;
	}

	const ttHideTopBar = params.get('tt_hide_top_bar');

	if (ttHideTopBar === 'true') {
		config.hideTopBar = true;
	}

	const name = params.get('tt_app_name');

	if (name) {
		config.name = name;
	}

	const themeColor = params.get('tt_theme_color');

	if (themeColor) {
		config.theme.ttColor = themeColor;
	}

	return config;
}

function parseFromServerJSON(serverConfig: unknown): IApplicationConfig {
	const config = structuredClone(BASE_APP_CONFIG);

	if (typeof serverConfig !== 'object' || !serverConfig) {
		return config;
	}

	return applicationConfigSchema.cast(serverConfig);
}

export default {
	parseConfigurationFromQueryParams,
	parseFromServerJSON,
};
